import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import LogoutIcon from '@mui/icons-material/Logout'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import withStyles from '@material-ui/core/styles/withStyles'
import Logo from '../../logo.svg'
import User from './User'
import routes from '../../utilities/routes'
import { useAuth } from '@praxis/component-auth'
import { Tooltip } from '@material-ui/core'

const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#8c1f27',
    color: '#ffffff',
  },
  logo: {
    height: 40,
    marginRight: '0.5em',
    width: 35,
    color: '#ffffff',
  },
  menu: {
    color: '#ffffff',
    fontSize: '2.5rem',
  },
  spacer: {
    flexGrow: 1,
  },
  appName: {
    fontSize: '18px',
    fontWeight: '400',
  },
})

const Header = (props) => {
  const { classes } = props
  const auth = useAuth() // connect to the \`AuthContext\` provided by \`App\` below, using a React hook.
  const { isAuthorized, logout } = auth

  return (
    <AppBar color="default" className={classes.appBar}>
      <Toolbar>
        <Link to={routes.app.index} aria-label="Go to home">
          <Logo className={classes.logo} />
        </Link>
        <div className={classes.appName} style={{ fontSize: '24px' }}>
          Third Party Risk Management
        </div>
        <span className={classes.spacer} />
        <div></div>
        <User onClick={logout} isAuthorized={isAuthorized} />
        <Tooltip title={<h9 style={{ fontSize: '11px' }}>Logout</h9>}>
          <IconButton
            onClick={logout}
            isAuthorized={isAuthorized}
            aria-label="Logout"
          >
            <LogoutIcon
              onClick={logout}
              style={{ fill: '#FFFFFF' }}
              fontSize="large"
            />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Header)
