import { getEnvConfig } from '@praxis/component-runtime-env'
import { merge } from 'lodash'

import commonConfig from './commonConfig'

const apiConfig = async () => {
  const envConfig = await getEnvConfig()
  return merge(commonConfig, envConfig)
}

export default apiConfig
