import React from 'react'
import { AuthProvider } from '@praxis/component-auth'
import { useEnv } from '@praxis/component-runtime-env'
import Layout from './components/layout/Layout'
import BPContextProvider from './components/contexts/BPContext'

export const Main = () => {
  return (
    <React.Fragment>
      <Layout />
    </React.Fragment>
  )
}

export const App = () => {
  const env = useEnv()
  return (
    <BPContextProvider>
      <AuthProvider {...env.auth}>
        <Main />
      </AuthProvider>
    </BPContextProvider>
  )
}
export default App
