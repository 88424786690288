import React, { useContext, useState, useEffect } from 'react'
import { useAuth } from '@praxis/component-auth'
import * as Survey from 'survey-react'
import * as widgets from 'surveyjs-widgets'
import 'survey-react/survey.css'

import 'jquery-ui/themes/base/all.css'
import 'nouislider/distribute/nouislider.css'
import 'select2/dist/css/select2.css'
import 'bootstrap-slider/dist/css/bootstrap-slider.css'

import 'jquery-bar-rating/dist/themes/css-stars.css'
// import 'bootstrap/dist/css/bootstrap.css'

import $ from 'jquery'
import 'jquery-ui/ui/widgets/datepicker.js'
import 'select2/dist/js/select2.js'
import 'jquery-bar-rating'

import 'pretty-checkbox/dist/pretty-checkbox.css'
import CircularLoading from '../../shared/CircularLoading'
import CustomizedSnackbars from '../../shared/CustomizedSnackbars'
import { Grid, Typography } from '@material-ui/core'

import {
  getFormAndAnswerByDeploymentId,
  createSurveryResponse,
  uploadFile,
  downloadFile,
  updateSurveryResponse,
} from '../../utilities/restService'
import {
  sanitizeHTML,
  containsMaliciousKeywords,
  sanitizeSurveyUrl,
} from '../../utilities/sanitize'
import { useEnv } from '@praxis/component-runtime-env'
import { BPContext } from '../contexts/BPContext'
import 'react-quill/dist/quill.snow.css'
import '../../utilities/QuillEditortcustomwidget'
import '../../utilities/customMethods'
import { v4 as uuid } from 'uuid'
import { useParams } from 'react-router-dom'
window['$'] = window['jQuery'] = $

var mainColor = '#8c1f27'
var mainHoverColor = '#8c1f27'
var textColor = '#4a4a4a'
var headerColor = '#8c1f27'
var headerBackgroundColor = '#ffff'
var bodyContainerBackgroundColor = '#f8f8f8'

var defaultThemeColorsSurvey = Survey.StylesManager.ThemeColors['default']
defaultThemeColorsSurvey['$main-color'] = mainColor
defaultThemeColorsSurvey['$main-hover-color'] = mainHoverColor
defaultThemeColorsSurvey['$text-color'] = textColor
defaultThemeColorsSurvey['$header-color'] = headerColor
defaultThemeColorsSurvey['$header-background-color'] = headerBackgroundColor
defaultThemeColorsSurvey['$body-container-background-color'] =
  bodyContainerBackgroundColor

Survey.StylesManager.ThemeCss[
  '.sv_main.sv_default_css .sv_progress-buttons__list li.sv_progress-buttons__list-element--current::before'
] = 'background-color: $main-color; border: 3px solid $main-hover-color;'
Survey.StylesManager.ThemeCss[
  '.sv_main.sv_default_css .sv_progress-buttons__list li.sv_progress-buttons__list-element--passed.sv_progress-buttons__list-element--current::before '
] = 'background-color: $main-color; border: 3px solid $main-hover-color;'
Survey.StylesManager.ThemeCss[
  '.sv_main.sv_default_css .sv_progress-buttons__list li.sv_progress-buttons__list-element--passed::before'
] = 'background-color: #ffffff; border: 3px solid $main-hover-color;'
Survey.StylesManager.ThemeCss[
  '.sv_main.sv_default_css .sv_progress-buttons__list li::before'
] = 'background-color: #ffffff; border: 3px solid $main-hover-color;'

Survey.StylesManager.applyTheme()

widgets.prettycheckbox(Survey)
widgets.select2(Survey, $)
widgets.inputmask(Survey)
widgets.jquerybarrating(Survey, $)
widgets.jqueryuidatepicker(Survey, $)
widgets.nouislider(Survey)
widgets.select2tagbox(Survey, $)
widgets.sortablejs(Survey)
widgets.ckeditor(Survey)
widgets.autocomplete(Survey, $)
widgets.bootstrapslider(Survey)

var surveyAnswerId = null

Survey.Serializer.addProperty('survey', {
  name: 'include_save_button',
  displayName: 'Include save button?',
  type: 'boolean',
  default: true,
  category: 'general',
  visibleIndex: 3,
})

//Add property
Survey.Serializer.addProperty('question', {
  name: 'include_pdf',
  displayName: 'Include in PDF?',
  type: 'boolean',
  default: true,
  category: 'general',
  visibleIndex: 3,
})

//Add property
Survey.Serializer.addProperty('question', {
  name: 'role',
  default: 'user',
  choices: ['user', 'user-readOnly', 'admin', 'admin-user'],
  category: 'general',
})

//Add property
Survey.Serializer.addProperty('question', {
  name: 'includePdfIf:condition',
  category: 'logic',
})

function setPdfFlagTrue(params) {
  if (params.length < 1) return
  params[0].isPdfSend = true
  return params[0]
}
Survey.FunctionFactory.Instance.register('setPdfFlagTrue', setPdfFlagTrue)

function setEmailFlagTrue(params) {
  if (params.length < 1) return
  params[0].isEmailSend = true
  params[0].isPdfSend = true
  return params[0]
}
Survey.FunctionFactory.Instance.register('setEmailFlagTrue', setEmailFlagTrue)

function setPdfFlagFalse(params) {
  if (params.length < 1) return
  params[0].isPdfSend = false
  return params[0]
}
Survey.FunctionFactory.Instance.register('setPdfFlagFalse', setPdfFlagFalse)

function setEmailFlagFalse(params) {
  if (params.length < 1) return
  params[0].isEmailSend = false
  params[0].isPdfSend = false
  return params[0]
}
Survey.FunctionFactory.Instance.register('setEmailFlagFalse', setEmailFlagFalse)

// Include PDF Question Methods
function runIncludePdfIf(values, properties, question) {
  if (!question.includePdfIf) return
  const conditionRunner = new Survey.ConditionRunner(question.includePdfIf)
  conditionRunner.expression = question.includePdfIf
  conditionRunner.onRunComplete = (res) => {
    question.include_pdf = res
  }
  conditionRunner.run(values, properties)
}

export function SurveyAnswer(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [model, setModel] = useState({})
  const [surveyAnswer, setSurveyAnswer] = useState({})
  const [deploymentType, setDeploymentType] = useState('')
  const [deploymentStatus, setDeploymentStatus] = useState('')
  const [isSurveyLocked, setIsSurveyLocked] = useState('')
  const [isAnswerEditing, setIsAnswerEditing] = useState('')
  const auth = useAuth() // connect to the \`AuthContext\` provided by \`App\` below, using a React hook.
  const { session } = auth // assign variables of same name as properties off of \`auth\`
  const env = useEnv()
  let { deploymentId, answerId } = useParams()

  const { value, value2 } = useContext(BPContext)
  const [bpName] = value
  const [bpId] = value2

  useEffect(() => {
    Survey.ComponentCollection.Instance.clear()
    surveyAnswerId = answerId

    setIsLoading(true)

    getFormAndAnswerByDeploymentId(deploymentId, surveyAnswerId)
      .then((res) => {
        surveyAnswerId = res.data?.survey_answer_id
        setModel(res.data?.survey_form_json)
        setSurveyAnswer(res.data?.survey_answer_response)
        setDeploymentType(res.data?.deployment_type)
        setDeploymentStatus(res.data?.deployment_status)
        setIsSurveyLocked(res.data?.is_survey_locked)
        setIsAnswerEditing(res.data?.edit_mode)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }, [deploymentId, answerId, session])

  function onPartialSend(sender, options) {
    saveSurveyAnswers(sender, deploymentId, deploymentType, false)
  }

  function onDynamicPanelAdded(sender, options) {
    const unique_id = uuid()
    options.panel.questions.forEach(function (question) {
      if (question?.name === env?.demeterSubFormUniqueId) {
        question.value = unique_id
        question.readOnly = true
      }
    })
  }

  function isEmpty(value) {
    return value === undefined || value === null || value?.length === 0
  }

  function getEmailAnswers(question) {
    if (
      question?.value !== undefined &&
      question?.value !== null &&
      Object.keys(question?.value).length > 0
    ) {
      return {
        subject_email: question?.value?.subject_email,
        isEmailSend: question?.value?.isEmailSend,
        isPdfSend: question?.value?.isPdfSend,
      }
    } else {
      return {
        subject_email: question?.defaultValue?.subject_email,
        isEmailSend: question?.defaultValue?.isEmailSend,
        isPdfSend: question?.defaultValue?.isPdfSend,
      }
    }
  }

  function onComplete(sender, options) {
    options.showDataSaving()
    var values = survey.getFilteredValues()
    var properties = survey.getFilteredProperties()

    var result = sender?.data

    var request = {
      deployment_id: deploymentId,
      status: 'Completed',
      deployment_type: deploymentType,
    }
    sender.getAllQuestions().forEach(function (question) {
      runIncludePdfIf(values, properties, question) // Run the includePDFIF Expression

      var key = question.getValueName()
      if (question.getType() === 'checkbox' && key === 'Submit_to_Target') {
        result[key] = ['Yes']
      }

      if (question.getType() === 'emailnotification') {
        var emailAnswers = getEmailAnswers(question)
        result[key] = emailAnswers
      }
      if (result[key] === undefined || result[key] === null) {
        result[key] = ''
      }
    })
    Object.assign(request, { survey_answer_json: result })
    if (
      surveyAnswerId !== null &&
      surveyAnswerId !== undefined &&
      surveyAnswerId !== ''
    ) {
      updateSurveryResponse(request, surveyAnswerId)
        .then((res) => {
          options.showDataSavingSuccess()
          options.showDataSavingClear()
        })
        .catch((error) => {
          options.showDataSavingError()
        })
    } else {
      createSurveryResponse(request)
        .then((res) => {
          options.showDataSavingSuccess()
          options.showDataSavingClear()
        })
        .catch((error) => {
          options.showDataSavingError()
        })
    }
  }

  Survey.ChoicesRestfull.onBeforeSendRequest = function (sender, options) {
    options.request.setRequestHeader(
      'Authorization',
      session?.userInfo?.accessToken
    )
    options.request.setRequestHeader('x-api-key', env?.key)
  }

  if (
    Survey.ComponentCollection.Instance.getCustomQuestionByName('date') === null
  ) {
    Survey.ComponentCollection.Instance.add({
      name: 'Date',
      category: 'General',
      questionJSON: {
        type: 'text',
        name: 'question1',
        inputType: 'date',
      },
    })
  }

  if (
    Survey.ComponentCollection.Instance.getCustomQuestionByName(
      'emailnotification'
    ) === null
  ) {
    Survey.ComponentCollection.Instance.add({
      name: 'EmailNotification',
      category: 'Custom',
      elementsJSON: [
        {
          type: 'text',
          name: 'to_email',
          title: 'To',
          hideNumber: true,
        },
        {
          type: 'text',
          name: 'bcc_email',
          title: 'BCC',
          hideNumber: true,
        },
        {
          type: 'text',
          name: 'subject_email',
          title: 'Subject',
          hideNumber: true,
        },
        {
          type: 'comment',
          name: 'body_email',
          title: 'Email body',
          hideNumber: true,
        },
        {
          type: 'boolean',
          name: 'isEmailSend',
          title: 'Send Email?',
          hideNumber: true,
          defaultValue: 'true',
          isRequired: true,
        },
        {
          type: 'boolean',
          name: 'isPdfSend',
          title: 'Send PDF?',
          hideNumber: true,
          defaultValue: 'true',
          isRequired: true,
        },
      ],
    })
  }

  if (
    Survey.ComponentCollection.Instance.getCustomQuestionByName('workday') ===
    null
  ) {
    Survey.ComponentCollection.Instance.add({
      name: 'Workday',
      category: 'Custom',
      elementsJSON: [
        {
          type: 'text',
          name: 'workday',
          title: 'Email',
          defaultValue: session?.userInfo?.email,
        },
        {
          type: 'html',
          name: 'requesting',
          html: 'The data is requesting',
          visibleIf: '{email_request_processing} = true',
        },
        {
          type: 'html',
          name: 'error',
          html: '<div style=color:red><b>Please type the email correctly</b></div>',
          visibleIf: '{email_request_error} = true',
        },
      ],
    })
  }

  var survey = new Survey.Model(model)
  survey.sendResultOnPageNext = true
  if (
    deploymentStatus === 'Completed' ||
    isSurveyLocked === 'true' ||
    isSurveyLocked
  ) {
    survey.mode = 'display'
  }
  if (isAnswerEditing === 'true') {
    survey.mode = 'display'
    Survey.StylesManager.applyTheme('darkrose')
  }

  if (surveyAnswer != null) {
    survey.data = surveyAnswer
  }

  survey.getAllQuestions().forEach(function (question) {
    if (question.getType() === 'quilleditor') {
      if (containsMaliciousKeywords(question.value)) {
        question.value = sanitizeHTML(question.value)
      }
    }
    if (question.getType() === 'file') {
      if (
        question.value !== undefined &&
        question.value !== null &&
        question.value.length > 0
      ) {
        const sanitizedUrl = sanitizeSurveyUrl(question.value)
        question.value = sanitizedUrl
      }
    }
    if (question.name === 'Target' && isEmpty(question?.value)) {
      question.value = bpName
      question.readOnly = true
    } else if (question.name === 'Vendor_ID' && isEmpty(question?.value)) {
      question.value = bpId?.toString()
      question.readOnly = true
    }
    if (question.name === 'Todays_Local_Date' && isEmpty(question?.value)) {
      var showLocalDate = new Date()
      var todaysLocalDateTime =
        showLocalDate.toLocaleDateString('en-US') +
        ' ' +
        showLocalDate.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })
      question.value = todaysLocalDateTime
      question.readOnly = true
    }
    if (question.name === 'Todays_Date' && isEmpty(question?.value)) {
      var showDate = new Date()
      const options = { timeZone: 'UTC' }
      var todaysDateTime =
        showDate.toLocaleDateString('en-US', options) +
        ' ' +
        showDate.toLocaleTimeString('en-US', options, {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })
      question.value = todaysDateTime
      question.readOnly = true
    }
    if (question.role === 'admin') {
      question.visible = false
    } else if (question.role === 'user-readOnly') {
      question.readOnly = true
    }
  })

  survey.onUploadFiles.add(function (survey, options) {
    var count = 0
    var test = []
    options.files.forEach(function (file) {
      const formData = new FormData()
      formData.append('file_name', file)

      uploadFile(formData).then((result) => {
        count++

        test.push({
          file: file,
          content: result?.data?.name,
        })

        if (options.files.length === count) {
          options.callback('success', test)
        }
      })
    })
  })

  survey.onDownloadFile.add(function (survey, options) {
    downloadFile(options.content).then((result) => {
      var file = new File([result?.data], options.fileValue.name)
      var reader = new FileReader()
      reader.onload = function (e) {
        options.callback('success', e.target.result)
      }
      reader.readAsDataURL(file)
    })
  })

  survey.onAfterRenderQuestion.add(function (sender, options) {
    if (options.question.getType() === 'file') {
      var container = document.createElement('div')
      container.className = 'my-preview-container'
      var fileElement =
        options.htmlElement.getElementsByClassName('sv_q_file')[0]
      if (!fileElement) {
        fileElement =
          options.htmlElement.getElementsByClassName('sv-file__decorator')[0]
      }
      fileElement.appendChild(container)
      options.question.onPropertyChanged.add(function (question, options) {
        if (options.name === 'value') {
          updatePreview(options.newValue, container)
        }
      })
      updatePreview(options.question.value, container)
    }
  })

  function updatePreview(newValue, container) {
    container.innerHTML = ''
    ;(newValue || []).forEach(function (fileItem) {
      var button = document.createElement('div')
      button.className = 'btn sv-btn sv-file__choose-btn'
      button.innerText = 'Download ' + fileItem.name
      button.onclick = function (e) {
        e.preventDefault()
        downloadFile(fileItem.content).then((result) => {
          var contentType = 'application/octet-stream'
          var a = document.createElement('a')
          var blob = new File([result?.data], { type: contentType })
          a.href = window.URL.createObjectURL(blob)
          a.download = fileItem.name
          a.click()
        })
      }
      container.appendChild(button)
    })
  }

  function saveSurveyAnswers(sender, deploymentId, deploymentType, showAlert) {
    var result = sender?.data

    var request = {
      deployment_id: deploymentId,
      status: 'Partial',
      deployment_type: deploymentType,
    }

    sender.getAllQuestions().forEach(function (question) {
      var key = question.getValueName()
      if (!result[key]) {
        result[key] = ''
      }
      if (question.getType() === 'emailnotification') {
        let emailAnswers = getEmailAnswers(question)
        result[key] = emailAnswers
      }
    })
    Object.assign(request, { survey_answer_json: result })
    if (
      surveyAnswerId !== null &&
      surveyAnswerId !== undefined &&
      surveyAnswerId !== ''
    ) {
      updateSurveryResponse(request, surveyAnswerId)
        .then((res) => {
          if (showAlert) {
            alert('Assessment response saved successfully.')
          }
          console.log('Assessment response saved successfully.')
        })
        .catch((error) => {})
    } else {
      createSurveryResponse(request)
        .then((res) => {
          surveyAnswerId = res?.data?.id
          console.log('Survey saved successfully.')
        })
        .catch((error) => {})
    }
  }

  function addSaveButton() {
    if (!isLoading && survey?.include_save_button) {
      var svNavDiv = $('.sv_nav').length
      var svNavButton = $('.sv_nav').children('button').length

      if (svNavDiv !== 0 && svNavButton === 0) {
        if (svNavDiv > 0) {
          var surveySaveBtn1 = document.createElement('button')
          surveySaveBtn1.onclick = function () {
            saveSurveyAnswers(survey, deploymentId, deploymentType, true)
          }

          surveySaveBtn1.innerHTML = 'Save'
          surveySaveBtn1.stscript = 'float: right;margin-right: 25px'
          surveySaveBtn1.backgroundColor = '#8c1f27'
          $('.sv_nav')[0].prepend(surveySaveBtn1)
        }
        if (svNavDiv > 1) {
          var surveySaveBtn2 = document.createElement('button')
          surveySaveBtn2.onclick = function () {
            saveSurveyAnswers(survey, deploymentId, deploymentType, true)
          }

          surveySaveBtn2.innerHTML = 'Save'
          surveySaveBtn2.style.cssText = 'float: right;margin-right: 25px'
          surveySaveBtn2.backgroundColor = '#8c1f27'
          $('.sv_nav')[1].prepend(surveySaveBtn2)
        }
      }
    }
  }

  if (!isLoading && deploymentType !== '') {
    if (
      (isAnswerEditing === 'false' || !isAnswerEditing) &&
      deploymentStatus !== 'Completed' &&
      !(isSurveyLocked === 'true' || isSurveyLocked)
    ) {
      setTimeout(addSaveButton)
    }
  }
  return (
    <React.Fragment>
      <div className="container">
        {!isLoading ? (
          <>
            <Grid container justify="space-between">
              {surveyAnswerId !== null ? (
                <Grid item xs={11} align="right">
                  <Typography inline align="right">
                    <div
                      style={{
                        color: '#a62e37',
                        font: 'Helvetica',
                        fontSize: '26px',
                      }}
                    >
                      Record ID: {surveyAnswerId}
                    </div>
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={11} align="right">
                  <Typography inline align="right">
                    <div
                      style={{
                        color: '#a62e37',
                        font: 'Helvetica',
                        fontSize: '26px',
                      }}
                    >
                      Record ID: UA
                    </div>
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Survey.Survey
                  model={survey}
                  onComplete={onComplete}
                  onPartialSend={onPartialSend}
                  onDynamicPanelAdded={onDynamicPanelAdded}
                  align="left"
                />
              </Grid>
            </Grid>
            {isAnswerEditing === 'true' &&
            (deploymentStatus !== 'Completed' || isSurveyLocked !== 'true') ? (
              <CustomizedSnackbars
                variant="warning"
                message="Survey Answer being edited by another user"
              />
            ) : (
              ''
            )}
          </>
        ) : (
          <CircularLoading />
        )}
      </div>
    </React.Fragment>
  )
}
