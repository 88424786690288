import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import green from '@material-ui/core/colors/green'

const faTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#8c1f27',
    },
    secondary: {
      main: '#fff',
    },
    success: {
      main: green[600],
    },
  },
  overrides: {
    MUIDataTableHeadCell: {
      fixedHeader: {
        zIndex: 0,
      },
      fixedHeaderCommon: {
        zIndex: 0,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 15,
      },
    },
    MuiInput: {
      root: {
        fontSize: 14,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 13,
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
      },
    },
    MuiButton: {
      containedPrimary: {
        fontSize: 12,
      },
      label: {
        fontSize: 13,
      },
    },
    MuiTypography: {
      h6: {
        fontSize: 19,
      },
    },
    MuiTableCell: {
      root: {
        fontSize: 13,
      },
    },
    MuiTablePagination: {
      select: {
        fontSize: 12,
      },
      caption: {
        fontSize: 13,
      },
    },
  },
})

export default faTheme
