import 'date-fns'
import React, { useState, useEffect } from 'react'
import { useAuth } from '@praxis/component-auth'
import { Helmet } from 'react-helmet'
// import { useHistory } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import muiTheme from '../../config/themeConfig'
import {
  getAllDeploymentsByEmail,
  getSurveyAnserById,
} from '../../utilities/restService'
import CircularLoading from '../../shared/CircularLoading'
import Grid from '@material-ui/core/Grid'
import Collapse from '@material-ui/core/Collapse'
import PollIcon from '@material-ui/icons/Poll'
import Tooltip from '@material-ui/core/Tooltip'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Button,
} from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { Link } from 'react-router-dom'
import {
  ASSIGNED_DEPLOYMENT_TYPE_FILTERS_OPTIONS,
  ASSIGNED_DEPLOYMENT_TYPE_FILTERS,
  STATUS,
} from '../../utilities/constants'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = (theme) => ({
  root: {
    maxWidth: 300,
    minWidth: 300,
    marginLeft: 13,
    marginTop: 10,
    height: 170,
  },
  title: {
    fontSize: 13,
  },
  dropdownSize: {
    fontSize: 18,
  },
  iconSize: {
    width: 25,
    height: 25,
  },
  margin13: {
    marginLeft: 13,
    marginTop: 4,
  },
  marginTop11: {
    marginLeft: 13,
    marginTop: -11,
  },
  margin25: {
    marginTop: 25,
  },
  cardContent1: {
    backgroundColor: '#EAEAEA',
  },
  cardContent2: {
    backgroundColor: '#0000',
  },
})

const Survey = (props) => {
  const { classes } = props
  const auth = useAuth() // connect to the \`AuthContext\` provided by \`App\` below, using a React hook.
  const { session } = auth // assign variables of same name as properties off of \`auth\`
  const [isLoading, setIsLoading] = useState(false)
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [deploymentFilterType, setDeploymentFilterType] = useState(
    ASSIGNED_DEPLOYMENT_TYPE_FILTERS_OPTIONS.MY_OPEN_DEPLOYMENTS
  )

  //const [oneTimeSurveyData, setOneTimeSurveyData] = useState([])
  const [PersistentSurveyData, setPersistentSurveyData] = useState([])
  const [tprmId, setTprmId] = useState('')
  //let history = useHistory()

  useEffect(() => {
    setIsLoading(true)
    getAllDeploymentsByEmail(STATUS.OPEN)
      .then((res) => {
        // setOneTimeSurveyData(
        //   res.data.filter((one) => one.deployment_type === 'One Time')
        // )
        setPersistentSurveyData(
          res.data.filter((p) => p.deployment_type === 'Persistent')
        )
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }, [session])

  function getDeployments(status) {
    getAllDeploymentsByEmail(status)
      .then((res) => {
        setPersistentSurveyData(
          res.data.filter((p) => p.deployment_type === 'Persistent')
        )
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  function handleDeploymentFilter(value) {
    setDeploymentFilterType(value)
    setIsLoading(true)

    if (
      value === ASSIGNED_DEPLOYMENT_TYPE_FILTERS_OPTIONS.MY_OPEN_DEPLOYMENTS
    ) {
      getDeployments(STATUS.OPEN)
    } else if (
      value ===
      ASSIGNED_DEPLOYMENT_TYPE_FILTERS_OPTIONS.MY_COMPLETED_DEPLOYMENTS
    ) {
      getDeployments(STATUS.COMPLETED)
    }
    setIsLoading(false)
  }

  function handleGetSurveyAnserById() {
    setIsSearchLoading(true)
    setIsError(false)
    getSurveyAnserById(tprmId)
      .then((res) => {
        if (res.data === '') {
          setIsError(true)
        } else {
          window.open(
            '/surveyAnswerUpdateUrl/' +
              res.data.deployment_id +
              '/answers/' +
              res.data.id
          )
        }
        setIsSearchLoading(false)
      })
      .catch((error) => {
        setIsSearchLoading(false)
      })
  }
  //Adding error attribute to the text field if error is true
  var inputProps = isError && {
    error: 'error',
  }

  return (
    <React.Fragment>
      <Helmet title="Third Party Risk Management" />
      <div>
        <MuiThemeProvider theme={muiTheme}>
          {isLoading ? (
            <CircularLoading />
          ) : (
            <div>
              <br />
              <Grid container>
                <Grid item xs={2} className={classes.margin13}>
                  <FormControl fullWidth>
                    <Select
                      className={classes.dropdownSize}
                      labelId="deploymentFilterType-select-label"
                      id="deploymentFilterType-select"
                      name="deploymentFilterType"
                      value={deploymentFilterType}
                      onChange={(e) => handleDeploymentFilter(e.target.value)}
                    >
                      {ASSIGNED_DEPLOYMENT_TYPE_FILTERS.map((d, index) => {
                        return (
                          <MenuItem
                            className={classes.dropdownSize}
                            key={index}
                            value={d}
                          >
                            {d}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} />
                <Grid xs={2} className={classes.marginTop11}>
                  <FormControl fullWidth>
                    <TextField
                      {...inputProps}
                      id="tprmId"
                      type="number"
                      label="TPRM ID or ArcherId"
                      name="tprmId"
                      helperText={isError && 'Record not found'}
                      preserveWhiteSpace={true}
                      value={tprmId}
                      onChange={(e) => setTprmId(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl className={classes.formControl}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        tprmId.toString().trim().length <= 2 || isSearchLoading
                      }
                      onClick={handleGetSurveyAnserById}
                    >
                      Search
                    </Button>
                    {isSearchLoading && <CircularProgress />}
                  </FormControl>
                </Grid>

                <Grid item>
                  <FormControl className={classes.formControl}></FormControl>
                </Grid>
              </Grid>
              <br />
              <Grid container>
                <Grid item xs={11}>
                  <Typography variant="h4" className={classes.margin13}>
                    Ongoing Assessments
                  </Typography>
                </Grid>
              </Grid>
              <br />
              <Grid container>
                {PersistentSurveyData.map((persistent, index) => {
                  return (
                    <Grid item key={index}>
                      <Card className={classes.root}>
                        <Collapse
                          style={{ backgroundColor: '#aaa' }}
                          collapsedHeight={'5.6em'}
                          timeout="auto"
                          title={persistent.name}
                        >
                          <CardContent>
                            <Typography
                              variant="h1"
                              style={{
                                color: '#333',
                                fontSize: '17px',
                                fontWeight: 'bold',
                              }}
                            >
                              {persistent.name !== null &&
                              persistent.name !== undefined &&
                              persistent.name !== ''
                                ? persistent.name.length > 70
                                  ? persistent.name.slice(0, 70) + '...'
                                  : persistent.name
                                : ''}
                            </Typography>
                          </CardContent>
                        </Collapse>
                        <CardContent
                          className={
                            persistent.status === STATUS.COMPLETED
                              ? classes.cardContent1
                              : classes.cardContent2
                          }
                        >
                          <Typography className={classes.title}>
                            Deployment Id: {persistent.id}
                          </Typography>
                        </CardContent>
                        <CardActions
                          className={
                            persistent.status === STATUS.COMPLETED
                              ? classes.cardContent1
                              : classes.cardContent2
                          }
                        >
                          {persistent.status === STATUS.COMPLETED ||
                          persistent.is_archer_pull_request ? (
                            ''
                          ) : (
                            <Tooltip
                              title={<h9 style={{ fontSize: '11px' }}>New</h9>}
                            >
                              <Link
                                color={'primary'}
                                to={{
                                  pathname: '/surveyAnswerUrl/' + persistent.id,
                                }}
                                style={{ textDecoration: 'none' }}
                              >
                                <AddCircleIcon
                                  className={classes.iconSize}
                                  style={{ fill: '#3379B7' }}
                                />
                              </Link>
                            </Tooltip>
                          )}

                          <Tooltip
                            title={
                              <h9 style={{ fontSize: '11px' }}>viewRecords</h9>
                            }
                          >
                            <Link
                              color={'primary'}
                              to={{
                                pathname:
                                  'surveyAnswers/' +
                                  persistent.id +
                                  '/' +
                                  persistent.name,
                              }}
                              style={{ textDecoration: 'none' }}
                            >
                              <PollIcon
                                className={classes.iconSize}
                                style={{ fill: '#3379B7' }}
                              />
                            </Link>
                          </Tooltip>
                        </CardActions>
                      </Card>
                    </Grid>
                  )
                })}
              </Grid>
              <Grid container>
                <Grid item xs={11}>
                  <Typography
                    variant="h4"
                    className={classes.margin25}
                  ></Typography>
                </Grid>
              </Grid>
            </div>
          )}
        </MuiThemeProvider>
      </div>
    </React.Fragment>
  )
}

export default withStyles(styles)(Survey)
