export const commonConfig = {
  auth: {
    authorizationPath: '/auth/oauth/v2/authorize',
    popupOptions: { width: 482, height: 680 },
    redirectUri: `${window.location.origin}/auth/login`,
    responseType: 'token id_token',
    scope: ['openid profile'],
    storageType: 'localStorage',
    tokenType: 'Bearer',
  },
  teams: {
    core: 3682,
    trustedCommitters: 4182,
  },
  githubProxy: {
    img: 'getImage?userID=',
    imgFallbackRoot: 'https://avatars.git.target.com/u/',
    team: 'team?teamID=',
    contributions: 'contributions',
    releases: 'releases?owner=Praxis-Framework&repo=',
  },
}
export default commonConfig
