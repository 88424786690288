import { Helmet } from 'react-helmet'
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { useAuth } from '@praxis/component-auth'
import LogoutIcon from '@mui/icons-material/Logout'
import { Tooltip } from '@material-ui/core'

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '30vw',
    margin: '2em auto',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
})
const ContactTypography = withStyles({
  root: {
    color: '#0E86D4',
  },
})(Typography)

const AccessDeniedPage = (props) => {
  const { classes } = props
  const auth = useAuth()
  const { logout } = auth

  return (
    <Paper className={classes.root} elevation={1} data-testid="AccessDenied">
      <Helmet title="Access Denied" />
      <div>
        <Typography variant="h4">
          You are not authorized as an Third Party Risk Management User
        </Typography>
      </div>
      <div>
        <Typography variant="h5" component="div">
          <ul>
            <strong>
              Please Contact your Target representative for assistance at{' '}
            </strong>
            <ContactTypography variant="h6" component="div">
              <strong>
                <a href="mailto:DemeterTPRM@target.com">
                  DemeterTPRM@target.com{' '}
                </a>
              </strong>
            </ContactTypography>
          </ul>
        </Typography>
        <Tooltip title={<h9 style={{ fontSize: '11px' }}>Exit</h9>}>
          <LogoutIcon
            onClick={logout}
            style={{ fill: '#8c1f27' }}
            fontSize="large"
          />
        </Tooltip>
      </div>
    </Paper>
  )
}

export default withStyles(styles)(AccessDeniedPage)
