import React from 'react'
import axios from 'axios'
import CustomizedSnackbars from '../../shared/CustomizedSnackbars'
import moment from 'moment-timezone/builds/moment-timezone-with-data'

export class Interceptor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      authInterceptor: undefined,
      errorInterceptor: undefined,
      isError: false,
      message: '',
    }
  }

  componentDidMount() {
    this.sessionTimeOutCheck()

    this.addAuthInterceptor()
    this.addErrorInterceptor()
  }

  sessionTimeOutCheck() {
    if (this.props.session && this.props.session?.userInfo) {
      if (this.props.session?.access) {
        if (this.props.session?.hasExpired) {
          this.setState({
            isError: true,
            message: 'Session is expired. Please re-login',
          })
          this.props.logout()
        }
        const tokenExpires = this.props.session?.access?.exp

        this.warningTimeout = setTimeout(() => {
          this.setState({
            isError: true,
            message: 'Session expires in few minutes',
          })
        }, tokenExpires * 1000 - moment().valueOf() - 4200000)
        this.logoutTimeout = setTimeout(() => {
          this.props.logout()
        }, tokenExpires * 1000 - moment().valueOf() - 3600000)
      }
    }
  }

  componentWillUnmount() {
    if (this.warningTimeout) {
      clearTimeout(this.warningTimeout)
    }
    if (this.logoutTimeout) {
      clearTimeout(this.logoutTimeout)
    }
    this.removeAuthInterceptor()
    this.removeErrorInterceptor()
  }

  addAuthInterceptor = () => {
    const authInterceptor = axios.interceptors.request.use(
      (config) => {
        if (this.props.session?.hasExpired) {
          this.props.logout()
        }
        if (!config.headers.common['Authorization']) {
          if (this.props.session?.userInfo?.accessToken) {
            config.headers.Authorization =
              this.props.session?.userInfo?.accessToken
          }
        } else if (!config.headers.Authorization) {
          delete config.headers.Authorization
        }
        config.headers['x-api-key'] = this.props.env?.key
        config.headers['X-TGT-LANID'] = this.props.session?.userInfo?.lanId
        config.headers['X-Tgt-Mail'] = this.props.session?.userInfo?.email
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
    this.setState({ authInterceptor })
  }

  removeAuthInterceptor = () => {
    axios.interceptors.request.eject(this.state.authInterceptor)
    this.setState({ authInterceptor: undefined })
  }

  addErrorInterceptor = () => {
    const errorInterceptor = axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (error.response) {
          this.setState({
            isError: false,
          })
          const response = error.response
          const code = response.status
          let message = 'Something went wrong.'
          if (code === 401) {
            message = 'Session is expired. Please re-login'
          } else {
            if (code === 403) {
              message = 'You’re not authorized to do that.'
            } else if (code === 504) {
              message = 'Something went wrong.'
            } else if (code === 415) {
              message =
                'Please upload only supported file types (Images/MS office/PDF/ZIP)'
            } else if (
              code === 500 &&
              error?.response?.data !== null &&
              error?.response?.data !== undefined &&
              error?.response?.data !== '' &&
              error?.response?.data.message?.includes('UNAUTHORIZED')
            ) {
              message = 'Not authorised to access this Survey Form'
            } else if (
              code === 500 &&
              error?.response?.data !== null &&
              error?.response?.data !== undefined &&
              error?.response?.data !== '' &&
              error?.response?.data.message?.includes('413')
            ) {
              message = 'File Size is too big'
            } else if (
              code === 500 &&
              error?.response?.data !== null &&
              error?.response?.data !== undefined &&
              error?.response?.data !== '' &&
              error?.response?.data.message?.includes('invalid')
            ) {
              message = error.response.data.message
            }
          }
          this.setState({
            isError: true,
            message: message,
          })
        }
        return Promise.reject(error)
      }
    )
    this.setState({ errorInterceptor })
  }

  removeErrorInterceptor = () => {
    axios.interceptors.request.eject(this.state.errorInterceptor)
    this.setState({ errorInterceptor: undefined })
  }

  render() {
    const { isError, message } = this.state
    return (
      <React.Fragment>
        {isError ? (
          <CustomizedSnackbars variant="error" message={message} />
        ) : (
          ''
        )}
      </React.Fragment>
    )
  }
}
export default Interceptor
