export const routes = {
  app: {
    index: '/',
    surveyAnswerUrl: '/surveyAnswerUrl/:deploymentId',
    surveyAnswerUpdateUrl:
      '/surveyAnswerUpdateUrl/:deploymentId/answers/:answerId',
    surveyAnswers: '/surveyAnswers/:deploymentId/:deploymentName',
  },
}
export default routes
