import axios from 'axios'
import apiConfig from '../config/apiConfig'

const headerConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
}
const uploadConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
}

const downloadConfig = {
  headers: {
    'Content-Type': 'application/octet-stream',
    Accept: 'application/octet-stream',
  },
  responseType: 'arraybuffer',
}

// Survey Answer Rest Calls

export const getSurveryResponseByDeploymentIdAndUserId = async (
  deploymentId
) => {
  const config = await apiConfig()
  return axios.get(
    `${config.fieldAssessment.host}/survey_answers/deployments_and_answers/` +
      deploymentId +
      `?key=` +
      config.key,
    headerConfig
  )
}

export const createSurveryResponse = async (request) => {
  const config = await apiConfig()
  return axios.post(
    config.fieldAssessment.host + '/survey_answers?key=' + config.key,
    JSON.stringify(request),
    headerConfig
  )
}
export const updateSurveryResponse = async (request, surveyAnswerId) => {
  const config = await apiConfig()
  return axios.put(
    config.fieldAssessment.host +
      `/survey_answers/` +
      surveyAnswerId +
      `?key=` +
      config.key,
    JSON.stringify(request),
    headerConfig
  )
}

// Deployment rest calls

export const getAllDeploymentsByEmail = async (status) => {
  const config = await apiConfig()
  return axios.get(
    config.fieldAssessment.host +
      '/deployments?status=' +
      status +
      '&key=' +
      config.key,
    headerConfig
  )
}

export const getFormAndAnswerByDeploymentId = async (
  deploymentId,
  answerId
) => {
  const config = await apiConfig()
  var url =
    config.fieldAssessment.host +
    '/deployments/survey_forms_and_survey_answer/' +
    deploymentId
  if (answerId !== null && answerId !== undefined) {
    url = url + '/?survey_answer_id=' + answerId
  }
  return axios.get(url, headerConfig)
}

export const getSurveyAnserById = async (tprmId) => {
  const config = await apiConfig()
  var url =
    config.fieldAssessment.host + '/survey_answers/id_or_archerId/' + tprmId
  return axios.get(url, headerConfig)
}

// Recipient rest calls

export const getRecipientsByEmailId = async (email) => {
  const config = await apiConfig()
  return axios.get(
    config.fieldAssessment.host +
      '/users?work_email=' +
      email +
      '&key=' +
      config.key,
    headerConfig
  )
}

export const getVendorInfoByUserId = async (lanId) => {
  const config = await apiConfig()
  return axios.get(
    config.fieldAssessment.host + '/vendors/user/' + lanId,
    headerConfig
  )
}

//File section

export const uploadFile = async (files) => {
  const config = await apiConfig()
  return axios.post(
    `${config.fieldAssessment.host}/files/upload?key=` + config.key,
    files,
    uploadConfig
  )
}
export const downloadFile = async (fileName) => {
  const config = await apiConfig()
  return axios.get(
    `${config.fieldAssessment.host}/files/download?file_name=` + fileName,
    downloadConfig
  )
}

// Location section

export const getAllLocations = async () => {
  const config = await apiConfig()
  return axios.get(
    config.fieldAssessment.host + '/locations?&key=' + config.key,
    headerConfig
  )
}
