import { useState, createContext } from 'react'

export const BPContext = createContext()

function BPContextProvider(props) {
  const [bpName, setBpName] = useState('')
  const [bpId, setBpId] = useState('')

  const value = { value: [bpName, setBpName], value2: [bpId, setBpId] }
  return <BPContext.Provider value={value}>{props.children}</BPContext.Provider>
}

export default BPContextProvider
