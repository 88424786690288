import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { ProtectedElement, useAuth } from '@praxis/component-auth'
import HomePage from '../surveyAnswer/HomePage'
import { SurveyAnswer } from '../surveyAnswer/SurveyAnswer'
import routes from '../../utilities/routes'
import { useEnv } from '@praxis/component-runtime-env'
import withStyles from '@material-ui/core/styles/withStyles'
import SurveyAnswersReport from '../surveyAnswer/SurveyAnswersReport'
import { Interceptor } from './Interceptor'
import AppHeader from './Header'
import ToolbarSpacer from '../../shared/ToolbarSpacer'
import AccessDeniedPage from '../../shared/AccessDeniedPage'

const styles = (theme) => ({
  iconFontSize: {
    fontSize: '2.5rem',
  },
  textFontSize: {
    fontSize: '14px',
  },
})
const Layout = (props) => {
  const auth = useAuth() // connect to the \`AuthContext\` provided by \`App\` below, using a React hook.
  const { logout, session } = auth // assign variables of same name as properties off of \`auth\`
  const env = useEnv()

  document.addEventListener('visibilitychange', function () {
    if (document.hidden) {
      console.log('Browser tab is hidden')
    } else {
      let cookies = () =>
        Object.fromEntries(
          document.cookie.split(';').map((it) => it.trim().split('='))
        )
      try {
        let ssosession =
          cookies()['SSOSESSIONINFO'] !== undefined &&
          window.atob(cookies()['SSOSESSIONINFO'])
        if (
          ssosession !== false &&
          ssosession !== undefined &&
          ssosession !== '' &&
          ssosession !== null
        ) {
          let user1 = JSON.parse(ssosession).user

          if (user1 !== session?.userInfo?.lanId) {
            //Invalid case
            localStorage.removeItem('access_token') //Remove token from LS will trigger the login route
            localStorage.removeItem('id_token')
            console.log(' POL session logged out or company changed.')

            window.location.reload() //Redirect to home page to login again
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
  })

  const RequiredAuth = ({ child }) => {
    const hasAccessAdminGroup = session?.userInfo?.memberOf?.includes(
      env.access.adminAccessAdGroup
    )

    if (hasAccessAdminGroup) {
      return child
    } else {
      return <Navigate to="/accessDenied" replace />
    }
  }

  return (
    <React.Fragment>
      <Interceptor session={session} env={env} logout={logout} />

      <BrowserRouter>
        <AppHeader />
        <ToolbarSpacer />
        {env.banner.isShowMessage && (
          <h4 style={{ padding: '10px', backgroundColor: 'yellow' }}>
            <p>{env.banner.message}</p>
            {env.banner.isShowLinks && (
              <p>
                {env.banner.showLinks.first.visible && (
                  <span style={{ padding: '10px' }}>
                    <a
                      target="_blank"
                      href={env.banner.showLinks.first.url}
                      rel="noreferrer"
                    >
                      {env.banner.showLinks.first.name}
                    </a>
                  </span>
                )}

                {env.banner.showLinks.second.visible && (
                  <span style={{ padding: '10px' }}>
                    <a
                      target="_blank"
                      href={env.banner.showLinks.second.url}
                      rel="noreferrer"
                    >
                      {env.banner.showLinks.second.name}
                    </a>
                  </span>
                )}

                {env.banner.showLinks.third.visible && (
                  <span style={{ padding: '10px' }}>
                    <a
                      target="_blank"
                      href={env.banner.showLinks.third.url}
                      rel="noreferrer"
                    >
                      {env.banner.showLinks.third.name}
                    </a>
                  </span>
                )}
              </p>
            )}
          </h4>
        )}
        <Routes>
          <Route
            exact
            path={routes.app.index}
            element={
              <ProtectedElement>
                <RequiredAuth child={<HomePage />} />
              </ProtectedElement>
            }
          />

          <Route
            exact
            path={routes.app.surveyAnswerUpdateUrl}
            element={
              <ProtectedElement>
                <RequiredAuth child={<SurveyAnswer />} />
              </ProtectedElement>
            }
          />

          <Route
            exact
            path={routes.app.surveyAnswerUrl}
            element={
              <ProtectedElement>
                <RequiredAuth child={<SurveyAnswer />} />
              </ProtectedElement>
            }
          />

          <Route
            exact
            path={routes.app.surveyAnswers}
            element={
              <ProtectedElement>
                <RequiredAuth child={<SurveyAnswersReport />} />
              </ProtectedElement>
            }
          />
          <Route
            path="*"
            element={
              <ProtectedElement>
                <AccessDeniedPage />
              </ProtectedElement>
            }
          />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  )
}

export default withStyles(styles)(Layout)
