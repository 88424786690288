import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import SignOutIcon from '@material-ui/icons/ExitToApp'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Avatar from '@material-ui/core/Avatar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useAuth } from '@praxis/component-auth'
import { getVendorInfoByUserId } from '../../utilities/restService'
import Card from '@material-ui/core/Card'
import { CardContent } from '@material-ui/core'
import { BPContext } from '../contexts/BPContext'

const styles = (theme) => ({
  avator: {
    color: '#BF2C2C',
    backgroundColor: '#ffff',
    width: '30px',
    height: '30px',
    fontSize: 'medium',
  },
  iconFontSize: {
    fontSize: '2.5rem',
  },
  textFontSize: {
    fontSize: '13px',
  },
  root: {
    height: 58.5,
  },
  cardColor: {
    backgroundColor: '#8C1F27',
    color: '#ffff',
  },
})

export const User = ({ classes }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const auth = useAuth() // connect to the \`AuthContext\` provided by \`App\` below, using a React hook.
  const { isAuthorized, logout, session } = auth // assign variables of same name as properties off of \`auth\`

  const { value, value2 } = useContext(BPContext)
  const [bpName, setBpName] = value
  const [, setBpId] = value2

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (
      isAuthorized &&
      session?.userInfo?.lanId !== undefined &&
      session?.userInfo?.lanId !== null
    ) {
      getVendorInfoByUserId(session?.userInfo?.lanId).then((res) => {
        setBpName(res.data?.bp_name)
        setBpId(res.data?.bp_id)
      })
    }
  }, [isAuthorized, session?.userInfo?.lanId, setBpName, setBpId])
  return isAuthorized ? (
    <React.Fragment>
      <Card className={classes.root} onClick={handleClick}>
        <CardContent className={classes.cardColor}>
          {session?.userInfo?.firstName !== undefined &&
          session?.userInfo?.lastName !== undefined ? (
            <>
              <Typography
                className={classes.textFontSize}
              >{`${session?.userInfo?.firstName} ${session?.userInfo?.lastName}`}</Typography>
              <Typography className={classes.textFontSize}>{bpName}</Typography>
            </>
          ) : (
            'Welcome, User'
          )}
        </CardContent>
      </Card>
      <Typography>&nbsp;</Typography>
      <Typography variant="subtitle1">
        <div className="userName" onClick={handleClick}>
          {session?.userInfo?.firstName !== undefined &&
          session?.userInfo?.lastName !== undefined ? (
            <Avatar
              className={classes.avator}
              title={`${session?.userInfo?.firstName} ${session?.userInfo?.lastName}`}
            >
              <span>
                {session?.userInfo?.firstName?.charAt(0) +
                  session?.userInfo?.lastName?.charAt(0)}
              </span>
            </Avatar>
          ) : (
            <Avatar
              className={classes.avator}
              title={`${session?.userInfo?.firstName} ${session?.userInfo?.lastName}`}
            ></Avatar>
          )}
        </div>
      </Typography>
      <div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              marginTop: '50px',
              width: '200px',
            },
          }}
        >
          <MenuItem onClick={logout}>
            <SignOutIcon className={classes.iconFontSize} />
            <div className={classes.textFontSize}>Logout</div>
          </MenuItem>
        </Menu>
      </div>
    </React.Fragment>
  ) : (
    ''
  )
}

User.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(User)
