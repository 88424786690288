export const CURRENT_URL = {
  ACCESS:
    window.location.href.includes('localhost') ||
    window.location.href.includes('dev') ||
    window.location.href.includes('prod'),
}

export const ASSIGNED_DEPLOYMENT_TYPE_FILTERS = ['Open', 'Completed']

export const ASSIGNED_DEPLOYMENT_TYPE_FILTERS_OPTIONS = {
  MY_OPEN_DEPLOYMENTS: 'Open',
  MY_COMPLETED_DEPLOYMENTS: 'Completed',
}

export const STATUS = {
  COMPLETED: 'Completed',
  OPEN: 'Open',
  ALL: 'all',
  LOCKED: 'Locked',
}
