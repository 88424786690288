import React from 'react'
import PropTypes from 'prop-types'
import MUIDataTable from 'mui-datatables'

export class MUIDataGrid extends React.Component {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
  }

  render() {
    const { title, rows, columns, options } = this.props
    return (
      <React.Fragment>
        <MUIDataTable
          title={title}
          data={rows}
          columns={columns}
          options={options}
        />
      </React.Fragment>
    )
  }
}

export default MUIDataGrid
