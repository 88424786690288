import * as SurveyKo from 'survey-knockout'
import * as Survey from 'survey-react'

var addDays = function (params) {
  if (params.length < 1 || !params[0]) return undefined
  var dt = new Date(params[0])
  let res = new Date(dt.toISOString().split('Z')[0])

  if (params.length < 2) return
  res.setDate(res.getDate() + params[1])

  var month = ('0' + (res.getMonth() + 1)).slice(-2)
  var date = ('0' + res.getDate()).slice(-2)
  var year = res.getFullYear()
  var shortDate = year + '-' + month + '-' + date
  return shortDate
}

var addMonths = function (params) {
  if (params.length < 1 || !params[0]) return undefined
  var res = new Date(params[0])
  if (params.length < 2) return
  res.setMonth(res.getMonth() + params[1])
  return res
}

function registerMethodsToSurvey(Survey) {
  Survey.FunctionFactory.Instance.register('addDays', addDays)
  Survey.FunctionFactory.Instance.register('addMonths', addMonths)
}

if (typeof SurveyKo !== 'undefined') {
  registerMethodsToSurvey(SurveyKo)
}

if (typeof Survey !== 'undefined') {
  registerMethodsToSurvey(Survey)
}
