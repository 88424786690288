import 'date-fns'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '@praxis/component-auth'
import { formatAMAndPM, timeZone } from '../../utilities/date'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import muiTheme from '../../config/themeConfig'
import MUIDataGrid from '../../shared/MUIDataGrid'
import { getSurveryResponseByDeploymentIdAndUserId } from '../../utilities/restService'
import CircularLoading from '../../shared/CircularLoading'
import { STATUS } from '../../utilities/constants'
const styles = (theme) => ({
  root: {
    maxWidth: 280,
    minWidth: 280,
    marginLeft: 13,
    marginTop: 10,
    height: 250,
  },
  title: {
    fontSize: 13,
  },
  iconSize: {
    width: 25,
    height: 25,
  },
  margin13: {
    marginLeft: 13,
    marginTop: 4,
  },
  margin25: {
    marginTop: 25,
  },
})

const SurveyAnswersReport = (props) => {
  const auth = useAuth() // connect to the \`AuthContext\` provided by \`App\` below, using a React hook.
  const { session } = auth // assign variables of same name as properties off of \`auth\`
  const [isLoading, setIsLoading] = useState(false)
  let { deploymentId, deploymentName } = useParams()
  const [surveyAnswers, setSurveyAnswers] = useState([])
  let navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    getSurveryResponseByDeploymentIdAndUserId(deploymentId)
      .then((res) => {
        res.data.sort(({ survey_locked: A }, { survey_locked: B }) => A - B)
        var resData = []
        res.data.forEach((element) => {
          var sObject = {
            id: element.id,
            status: element.survey_locked ? STATUS.LOCKED : element.status,
            deployment_id: element.deployment_id,
            submitted_by: element.submitted_by,
            submitted_date: element.submitted_date,
            archer_id: element.archer_id,
          }
          resData.push(sObject)
        })

        setSurveyAnswers(resData)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }, [deploymentId, session])

  // Group Columns
  const SURVEY_COLUMNS = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
      },
    },
    {
      name: 'archer_id',
      label: 'Archer Id',
      options: {
        filter: false,
      },
    },
    {
      name: 'deployment_id',
      label: 'Deployment Id',
      options: {
        filter: false,
      },
    },
    {
      name: 'submitted_by',
      label: 'Last Accessed By',
      options: {
        filter: true,
      },
    },
    {
      name: 'submitted_date',
      label: 'Last Accessed Date',
      options: {
        filter: true,
        customBodyRender: (value) =>
          value === null
            ? ''
            : formatAMAndPM(new Date(new Date(value) + timeZone)),
      },
    },
  ]

  const options = {
    rowsPerPage: 25,
    filterType: 'dropdown',
    responsive: 'standard',
    fixedHeader: true,
    tableBodyHeight: '75vh',
    rowsPerPageOptions: [10, 25, 50, 100, 200],
    rowHover: true,
    sort: true,
    filter: true,
    print: false,
    viewColumns: true,
    download: false,
    search: true,
    selectableRows: 'none',
    onRowClick: (rowData) => {
      navigate(
        '/surveyAnswerUpdateUrl/' + deploymentId + '/answers/' + rowData[0]
      )
    },
  }

  return (
    <React.Fragment>
      <Helmet title="Survey Answers Report" />
      <div>
        <MuiThemeProvider theme={muiTheme}>
          {isLoading ? (
            <CircularLoading />
          ) : (
            <div>
              <MUIDataGrid
                title={'Survey Answers of ' + deploymentName + ' deployment'}
                rows={surveyAnswers}
                columns={SURVEY_COLUMNS}
                options={options}
              />
            </div>
          )}
        </MuiThemeProvider>
      </div>
    </React.Fragment>
  )
}

export default withStyles(styles)(SurveyAnswersReport)
