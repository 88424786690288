const maliciousKeywordsRegex =
  /<script>|<img|iframe|<a|<object|rel=|<input|<link|javascript:|onerror=|onload=|alert|setTimeout|setInterval|style=|data:|<script/i

export const sanitizeHTML = (htmlContent) => {
  const doc = new DOMParser().parseFromString(htmlContent, 'text/html')
  return doc.body.textContent || ''
}

export const containsMaliciousKeywords = (inputString) => {
  return maliciousKeywordsRegex.test(inputString)
}

const sanitizeURLString = (str) => {
  var newStr = str.replace(
    /(<script>|<img|iframe|<a|<object|rel=|<link|javascript:|onerror=|onload=|alert|setTimeout|setInterval|style=|data:|<script)/gi,
    ''
  )

  return newStr.replace(/[&<>"':=]/g, (match) => {
    switch (match) {
      case '&':
        return '&amp;'
      case '<':
        return '&lt;'
      case '>':
        return '&gt;'
      case '"':
        return '&quot;'
      case "'":
        return '&apos;'
      case ':':
        return ''
      case '=':
        return ''
      default:
        return match
    }
  })
}

export const sanitizeSurveyUrl = (surveyURLInput) => {
  // Replace 'javascript:' and 'data:' from the URL to prevent XSS
  if (
    surveyURLInput !== undefined &&
    surveyURLInput !== null &&
    surveyURLInput.length > 0
  ) {
    return surveyURLInput?.map((fo) =>
      fo?.content !== '' && fo?.content !== null
        ? { ...fo, content: sanitizeURLString(fo.content) }
        : fo
    )
  } else {
    return surveyURLInput
  }
}
